/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        function view_region_centres($region) {
          //  ...filter the centres drop list to only show centres for the region
          //  var centres_for_region = globals.get_centres_for_region(region);
          if ($region=== 'View All Regions') {
            $('.footer-select-centre-name option').each(function()
            {
              $(this).show();
            });
          } else {
            $('.footer-select-centre-name option').each(function()
            {
                if($(this).attr('region-name') === $region){         
                  $(this).show();
                } else {
                  $(this).hide();
                }
            });
          }
          $('.footer-select-centre-name').selectpicker('refresh');
         }
               
        // JavaScript to be fired on all pages
        $(document).on("change",".footer-select-centre-region",function() {
          // filter list of centres not in region   
          view_region_centres( $(".footer-select-centre-region option:selected" ).text());          
        }); 

        $(document).on("change",".footer-select-centre-name",function() {
          var latlngcentre = $(".footer-select-centre-name option:selected").val().split('|');
          var centre_home_page_url = latlngcentre[2];
          
          if(centre_home_page_url ){
            window.location = centre_home_page_url;
          }         
        }); 
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
